import {render} from "@testing-library/react";
import MenuHeader from "./widgets/MenuHeader";
import {retry} from "@reduxjs/toolkit/query";
import MenuContacts from "./widgets/MenuContacts";
import MenuPen from "./widgets/MenuPen";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

function Menu() {

    const global = useSelector(state => state.global);
    const dispatch = useDispatch();


    useEffect(() => {
        document.title = global.page_title
        const parsers_string = global.parsers.map((element) => element.parser.toString() + ",").toString()
        const response = fetch("https://management.viglo.pro/api/parser/links?" + new URLSearchParams({name: parsers_string}),
            {
                    responseType: "json",
                    method: "GET"
                }
            ).then(response => response.json())
            .then(data => dispatch({type: "UPDATE_POSTS", payload: data}))
            .catch(error => console.error(error))
        console.log()
    }, []);

    return (
        <>
            <MenuHeader/>
            <MenuContacts/>
        </>

    )
}

export default Menu