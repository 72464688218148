import {useSelector} from "react-redux";

function MenuPen() {

    const global_reducer = useSelector(state => state.global)

    function clickToMain(e){
        window.open(global_reducer.main_url)
    }

    return (
        <div className="pen" onClick={clickToMain}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#ffffff">
                <path
                    d="M167-120q-21 5-36.5-10.5T120-167l40-191 198 198-191 40Zm191-40L160-358l458-458q23-23 57-23t57 23l84 84q23 23 23 57t-23 57L358-160Zm317-600L261-346l85 85 414-414-85-85Z"/>
            </svg>
        </div>
    )

}

export default MenuPen