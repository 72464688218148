import {useEffect, useRef} from "react";
import PostItem from "./widgets/PostItem";
import PostItemSubscribe from "./widgets/PostItemSubscribe";
import {useSelector} from "react-redux";

function Posts() {

    const bottomRef = useRef(null);

    const global_reducer = useSelector(state => state.global)

    function clickToMain(e){
        window.open(global_reducer.main_url)
    }

    useEffect(() => {
        const interval = setInterval(()=>{
            document.querySelectorAll("iframe").forEach(el => {
                el.style.colorScheme = "light"
            })
        },10)

        setTimeout(()=>{
            bottomRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
            clearInterval(interval)
        },2000)


    }, []);

    useEffect(() => {

    }, [global_reducer]);

    return (
        <>
            <div className="container active" id="chatBox">
                <div className="content-header" onClick={clickToMain}>
                    <div className="image" style={{cursor: "pointer"}}>
                        <img src={global_reducer.main_avatar_url} alt="" />
                    </div>
                    <div className="details">
                        <h3>{global_reducer.name}</h3>
                        <span>онлайн</span>
                    </div>
                    <div className="icons">

                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                             fill="#707579">
                            <path
                                d="M162-120q-18 0-30-12t-12-30v-162q0-13 9-23.5t23-14.5l138-28q14-2 28.5 2.5T342-374l94 94q38-22 72-48.5t65-57.5q33-32 60.5-66.5T681-524l-97-98q-8-8-11-19t-1-27l26-140q2-13 13-22.5t25-9.5h162q18 0 30 12t12 30q0 125-54.5 247T631-329Q531-229 409-174.5T162-120Zm556-480q17-39 26-79t14-81h-88l-18 94 66 66ZM360-244l-66-66-94 20v88q41-3 81-14t79-28Zm358-356ZM360-244Z"/>
                        </svg>

                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                             fill="#707579">
                            <path
                                d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z"/>
                        </svg>
                    </div>
                </div>
                <div className="chat-container">
                    <div className={"chat-container-scroller"} id={"widget-box"}>
                        {
                            global_reducer.posts !== [] ?
                                global_reducer.posts.map(
                                    (post) => {
                                        return <PostItem url={post.link} key={post.link}/>
                                    }
                                ) : <></>
                        }

                        <span id={"post_end"} ref={bottomRef}></span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Posts